<template>
  <div class="flex flex-row p-2">
    <router-link
      class="text-blue-400 border border-blue-300 px-4 py-1 rounded"
      to="/system/config/new"
      >添加</router-link
    >
  </div>
  <div class="w-full px-2 flex flex-col space-y-1 space-blue-200">
    <div v-for="cfg in config" :key="cfg.uid" class="flex flex-row bg-white">
      <div class="w-32 flex justify-center items-center bg-gray-300">
        <router-link class="text-gray-600" :to="`/system/config/${cfg.uid}`">
          {{ cfg.name }}
        </router-link>
      </div>
      <div class="w-full max-h-56 overflow-auto px-4 py-2">
        <pre class="text-gray-600 font-mono">{{ cfg.value }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SystemConfig",
  setup() {
    const store = useStore();
    const config = computed(() => store.state.config.all);
    onMounted(() => store.dispatch("config/all"));
    return { config };
  },
});
</script>
1
